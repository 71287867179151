import request, { METHOD } from "../utils/request";

class AdminService {
  static getAllCompanies({ page = 0, sort = "name", order = "ASC", filter }) {
    const {
      q = "",
      from = "",
      to = "",
      showDeleted = "false",
      nextInvoiceDateFrom = "",
      nextInvoiceDateTo = ""
    } = filter;
    return request({
      url: `/admin/companies?page=${page}&sort=${sort}&order=${order}&q=${q}&from=${from}&to=${to}&deleted=${showDeleted}&nextInvoiceDateFrom=${nextInvoiceDateFrom}&nextInvoiceDateTo=${nextInvoiceDateTo}`,
      method: METHOD.GET
    });
  }

  static getAllCompaniesSimplified = () =>
    request({
      url: "/admin/companiesSimplified",
      method: METHOD.GET
    });

  static getCompany(companyId) {
    return request({
      url: `/admin/companies/${companyId}`,
      method: METHOD.GET
    });
  }

  static getAllOrders(page = 0) {
    return request({
      url: `/admin/orders/${page}`,
      method: METHOD.GET
    });
  }

  static getAllClients() {
    return request({
      url: "/admin/clients",
      method: METHOD.GET
    });
  }

  static getAllUsers({ page, filter, sort = "user.lastname", order = "ASC" }) {
    const { q = "", showDeleted = "false", companyId = "" } = filter;
    return request({
      url: `/admin/users?page=${page}&sort=${sort}&order=${order}&q=${q}&deleted=${showDeleted}&companyId=${companyId}`,
      method: METHOD.GET
    });
  }

  static getAllUsersSimplified(companyId) {
    return request({
      url: `/admin/companies/${companyId}/usersSimplified`,
      method: METHOD.GET
    });
  }

  static getLastThreeMonthsPlans() {
    return request({
      url: "/admin/plans",
      method: METHOD.GET
    });
  }

  static addCompany(company) {
    return request({
      url: "/admin/companies",
      method: METHOD.POST,
      data: company
    });
  }

  static patchCompany(company) {
    return request({
      url: `/admin/companies/${company.id}`,
      method: METHOD.PATCH,
      data: company
    });
  }

  static deleteCompany(companyId) {
    return request({
      url: `/admin/companies/${companyId}`,
      method: METHOD.DELETE
    });
  }

  static addUser(user) {
    return request({
      url: "/admin/users",
      method: METHOD.POST,
      data: user
    });
  }

  static deleteUser(userId) {
    return request({
      url: `/admin/users/${userId}`,
      method: METHOD.DELETE
    });
  }

  static patchUser(user) {
    return request({
      url: `/admin/users/${user.id}`,
      method: METHOD.PATCH,
      data: user
    });
  }

  static getLogs(date) {
    return request({
      url: `/admin/logs?date=${date}`,
      method: METHOD.GET
    });
  }

  static logAs(userId) {
    return request({
      url: `/admin/jwt/${userId}`,
      method: METHOD.GET
    });
  }

  static runContactFinancialIdBatch() {
    return request({
      url: "/admin/batch/companies/syncFinancialId",
      method: METHOD.POST
    });
  }

  static runInjectAclCancelBatch() {
    return request({
      url: "/admin/batch/users/injectCancelAcl",
      method: METHOD.POST
    });
  }

  static runInjectInvoicesDueDateBatch() {
    return request({
      url: "/admin/batch/invoices/injectDueDate",
      method: METHOD.POST
    });
  }

  static runInjectEstimatesCashFormDepositsBatch() {
    return request({
      url: "/admin/batch/estimates/injectCashFormDeposits",
      method: METHOD.POST
    });
  }

  static runInjectOrderFormsCashFormDepositsBatch() {
    return request({
      url: "/admin/batch/orders/injectCashFormDeposits",
      method: METHOD.POST
    });
  }

  static resetInvoiceToDraft(invoiceId) {
    return request({
      url: "/admin/action/companies/resetInvoiceToDraft",
      method: METHOD.POST,
      data: {
        invoiceId
      }
    });
  }

  static addDocumentTemplate(data) {
    return request({
      url: "/admin/templates",
      method: METHOD.POST,
      data
    });
  }

  static deleteDocumentTemplate(templateId) {
    return request({
      url: `/admin/templates/${templateId}`,
      method: METHOD.DELETE
    });
  }

  static exportCompanies() {
    return request({
      url: "/admin/action/companies/exportToCSV",
      method: METHOD.POST
    });
  }

  static exportEstimates(companyId) {
    return request({
      url: "/admin/action/estimates/exportToCSV",
      method: METHOD.POST,
      data: { companyId }
    });
  }

  static exportOrderForms(companyId) {
    return request({
      url: "/admin/action/orderForms/exportToCSV",
      method: METHOD.POST,
      data: { companyId }
    });
  }

  static exportContacts(companyId) {
    return request({
      url: "/admin/action/contacts/exportToCSV",
      method: METHOD.POST,
      data: { companyId }
    });
  }

  static exportProducts(companyId) {
    return request({
      url: "/admin/action/products/exportToCSV",
      method: METHOD.POST,
      data: { companyId }
    });
  }

  static replicateProducts(fromCompanyId, toCompanyId) {
    return request({
      url: "/admin/action/products/replicate",
      method: METHOD.POST,
      data: { fromCompanyId, toCompanyId }
    });
  }

  static exportUsers(companyId) {
    return request({
      url: "/admin/action/users/exportToCSV",
      method: METHOD.POST,
      data: { companyId }
    });
  }

  static addProducts(companyId, products) {
    return request({
      url: `/admin/action/companies/${companyId}/importProducts`,
      method: METHOD.POST,
      data: { products }
    });
  }

  static addContacts(companyId, userId, contacts) {
    return request({
      url: `/admin/action/companies/${companyId}/importContacts`,
      method: METHOD.POST,
      data: { contacts, userId }
    });
  }

  static getCompanyProductsCategories(companyId) {
    return request({
      url: `/admin/companies/${companyId}/productsCategories`,
      method: METHOD.GET
    });
  }

  static getCompanyProducts(companyId) {
    return request({
      url: `/admin/companies/${companyId}/products`,
      method: METHOD.GET
    });
  }

  static deleteCompanyProducts(companyId) {
    return request({
      url: `/admin/action/companies/${companyId}/deleteProducts`,
      method: METHOD.POST,
      data: { companyId }
    });
  }

  static injectContactCegidData(companyId) {
    return request({
      url: `/admin/action/companies/${companyId}/injectContactCegidData`,
      method: METHOD.POST
    });
  }
}

export default AdminService;
