import { Button, Card, CardContent, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import SofincoLogo from "../components/estimate/complementaryStepForms/sofinco.svg";
import SofincoEquipmentCodeSelect from "../components/financing/SofincoEquipmentCodeSelect";
import FranfinanceLogo from "../components/estimate/complementaryStepForms/franfinance.png";
import SofincoCampaignIdSelect from "../components/financing/SofincoCampaignIdSelect";
import CompanyService from "../services/CompanyService";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  }
}));

function FinancingPage({ sessionStore }) {
  const classes = useStyles();
  const [company, setCompany] = useState({ ...sessionStore.user.company });
  const { enqueueSnackbar } = useSnackbar();

  const canEdit = sessionStore.userHasAccess({
    requiredAcls: ["SETTING_WRITE"],
    requiredFeatures: ["SETTING"]
  });

  const handleChange = e => {
    const { name, value } = e.target;
    setCompany({ ...company, [name]: value });
  };

  const handleSubmit = () => {
    CompanyService.patchCompany({
      ...company,
      brandStructure: company.brandStructure || "",
      equipmentCode: company.equipmentCode || "",
      franfinanceToken: company.franfinanceToken || "",
      sofincoPartnerId: company.sofincoPartnerId || "",
      sofincoCampaignId: company.sofincoCampaignId || "",
      sofincoBusinessProviderId: company.sofincoBusinessProviderId || ""
    })
      .then(() => {
        sessionStore.patchSessionCompany(company);
        enqueueSnackbar("Les paramètres ont été mis à jour", {
          variant: "success"
        });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue pendant la mise à jour des paramètres", {
          variant: "error"
        });
      });
  };

  return (
    <Grid container className={classes.container} spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Financement</Typography>
      </Grid>
      {canEdit ? (
        <Grid item xs={12}>
          <Alert severity="info">
            En cas de doute sur la configuration de cette page, rapprochez-vous d&apos;un administrateur KingKang
          </Alert>
        </Grid>
      ) : null}
      {sessionStore.userHasAccess({ requiredFeatures: ["OPTION_SOFINCO"] }) && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <img width={100} src={SofincoLogo} alt="Logo SOFINCO" />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Numéro d&apos;agrément
                  </Typography>
                  <TextField
                    size="small"
                    name="sofincoBusinessProviderId"
                    placeholder="XX"
                    label=""
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={company.sofincoBusinessProviderId}
                    onChange={handleChange}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Code enseigne
                  </Typography>
                  <TextField
                    size="small"
                    name="brandStructure"
                    placeholder="XX"
                    label=""
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={company.brandStructure}
                    onChange={handleChange}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Nature du bien
                  </Typography>
                  <SofincoEquipmentCodeSelect
                    hideLabel
                    value={company.equipmentCode}
                    onChange={handleChange}
                    selectProps={{ size: "small", disabled: !canEdit }}
                    formControlProps={{ size: "small" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Identifiant du partenaire
                  </Typography>
                  <TextField
                    size="small"
                    name="sofincoPartnerId"
                    placeholder="XX"
                    label=""
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={company.sofincoPartnerId || ""}
                    onChange={handleChange}
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Identifiant de la campagne
                  </Typography>
                  <SofincoCampaignIdSelect
                    value={company.sofincoCampaignId}
                    onChange={handleChange}
                    hideLabel
                    selectProps={{ size: "small", disabled: !canEdit }}
                    formControlProps={{ size: "small" }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
      {sessionStore.userHasAccess({ requiredFeatures: ["OPTION_FRANFINANCE"] }) && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <img width={100} src={FranfinanceLogo} alt="Logo FRANFINANCE" />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" style={{ marginBottom: 6, marginTop: 6 }}>
                    Chaine d&apos;authentification
                  </Typography>
                  <TextField
                    size="small"
                    name="franfinanceToken"
                    placeholder="xxxxxxxxx"
                    label=""
                    margin="none"
                    variant="outlined"
                    fullWidth
                    value={company.franfinanceToken}
                    onChange={handleChange}
                    disabled={!canEdit}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
      {canEdit && (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Enregistrer
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default inject("sessionStore")(observer(props => <FinancingPage {...props} />));
