import React from "react";
import { observable, action, computed } from "mobx";
import moment from "moment";
import { Decimal } from "decimal.js-light";

import CashForm from "../components/estimate/complementaryStepForms/CashForm";
import FinancingForm from "../components/estimate/complementaryStepForms/FinancingForm";
import Client from "../classes/Client";
import Product, { TVAS_DROPDOWN } from "../classes/Product";
import ValidatorService from "../services/ValidatorService";

const PAYMENT_CONDITIONS_VALUES = Object.freeze({ FINANCING: "FINANCING", CASH: "CASH" });

export const PAYMENT_CONDITIONS = [
  {
    label: "Financement",
    value: PAYMENT_CONDITIONS_VALUES.FINANCING,
    component: <FinancingForm />
  },
  {
    label: "Paiement comptant",
    value: PAYMENT_CONDITIONS_VALUES.CASH,
    component: <CashForm />
  }
];

export const PAYMENT_MODES = [
  { value: "cheque", label: "Chèque" },
  { value: "transfer", label: "Virement" },
  { value: "cash", label: "Espèces" },
  { value: "credit_card", label: "Carte bancaire" }
];

export const FINANCIAL_METHOD = Object.freeze({ PERCENTAGE: 1, HARD_VALUE: 2 });

const LIMIT_DATE_DAYS = 365;

const defaultFinancingType = "OTHER"; // OTHER | SOFINCO | FRANFINANCE | COFIDIS_PROJEXIO

const defaultFinancingValues = {
  financingInstitution: "",
  instalmentNumber: "",
  instalmentAmount: "",
  monthlyCostInsurance: "",
  taeg: "", // Taux Annuel Effectif Global
  overdrawnRate: "", // Taux Débiteur
  totalAmount: "",
  loanDuration: "", // Durée du prêt
  insuranceCount: 0, // Combien d'assurés
  instalmentAmountWithInsurance: "", // Mensualité avec assurance
  totalAmountWithInsurance: "", // Montant total avec assurance
  annualPercentageRateOfInsurance: "", // TAEA
  annualMarkUprate: "" // TMA
};

const defaultFinancingForm = {
  _sofinco: {
    scaleCode: "",
    equipmentCode: "",
    brandStructure: "",
    simulationResult: undefined,
    simulationDate: undefined
  },
  _franfinance: {
    simulationResult: undefined,
    simulationDate: undefined
  },
  financingType: defaultFinancingType,
  loanAmount: "0",
  depositMethod: FINANCIAL_METHOD.HARD_VALUE,
  deposit: "0",
  endWorkPayment: "0",
  ...defaultFinancingValues
};

const defaultCashForm = {
  depositMethod: FINANCIAL_METHOD.HARD_VALUE,
  deposit: "0"
};

const defaultAdditionalInformation = {
  defined: [],
  extra: ""
};

const defaultDeliveryAddress = {
  businessName: "",
  lastname: "",
  firstname: "",
  phone: "",
  email: "",
  address: "",
  zipCode: "",
  city: ""
};

class NewEstimateStore {
  @observable
  includePaymentBlock = false;

  @observable
  nature = "";

  @observable
  currency = "EUR";

  @observable
  details = [{ ...new Product(), discountInputValue: 0 }];

  @observable
  discount = "";

  @observable
  comments = "";

  @observable
  discountMethod = FINANCIAL_METHOD.PERCENTAGE;

  @observable
  limitDate = moment().add(LIMIT_DATE_DAYS, "days");

  @observable
  client = new Client();

  @observable
  paymentCondition = PAYMENT_CONDITIONS[0].value;

  @observable
  paymentMode = PAYMENT_MODES[0].value;

  @observable
  financingForm = defaultFinancingForm;

  @observable
  cashForm = defaultCashForm;

  @observable
  cashFormDeposits = [];

  @observable
  optimus = [];

  @observable
  financingType = defaultFinancingType;

  @observable
  additionalInformation = defaultAdditionalInformation;

  @observable
  deliveryAddress = defaultDeliveryAddress;

  @observable
  useSpecificDeliveryAddress = false;

  @action
  patchDeliveryAddress = address => {
    this.deliveryAddress = { ...this.deliveryAddress, ...address };
  };

  @action
  toggleUseSpecificDeliveryAddress = value => {
    this.useSpecificDeliveryAddress = value !== true && value !== false ? !this.useSpecificDeliveryAddress : value;
    this.patchDeliveryAddress({ ...this.client });
  };

  @action
  patchAdditionalInformation = value => {
    this.additionalInformation = { ...this.additionalInformation, ...value };
  };

  @action
  initWithClient = (client, configOverride = {}) => {
    this.nature = "";
    this.currency = "EUR";
    this.details = [{ ...new Product(), discountInputValue: 0 }];
    this.discount = "";
    this.discountMethod = FINANCIAL_METHOD.PERCENTAGE;
    this.limitDate =
      configOverride && configOverride.limitDate ? configOverride.limitDate : moment().add(LIMIT_DATE_DAYS, "days");
    this.client = new Client(client);
    this.paymentCondition = PAYMENT_CONDITIONS[0].value;
    this.paymentMode = PAYMENT_MODES[0].value;
    this.financingForm = defaultFinancingForm;
    this.cashForm = defaultCashForm;
    this.id = "";
    this.number = "";
    this.comments = "";
    this.includePaymentBlock = false;
    this.additionalInformation = defaultAdditionalInformation;
    this.useSpecificDeliveryAddress = false;
    this.deliveryAddress = defaultDeliveryAddress;
  };

  @action
  init = (configOverride = {}) => {
    this.nature = "";
    this.currency = "EUR";
    this.details = [{ ...new Product(), discountInputValue: 0 }];
    this.discount = "";
    this.discountMethod = FINANCIAL_METHOD.PERCENTAGE;
    this.limitDate =
      configOverride && configOverride.limitDate ? configOverride.limitDate : moment().add(LIMIT_DATE_DAYS, "days");
    this.client = new Client();
    this.paymentCondition = PAYMENT_CONDITIONS[0].value;
    this.paymentMode = PAYMENT_MODES[0].value;
    this.financingForm = defaultFinancingForm;
    this.cashForm = defaultCashForm;
    this.cashFormDeposits = [];
    this.id = "";
    this.number = "";
    this.comments = "";
    this.optimus = [];
    this.includePaymentBlock = false;
    this.additionalInformation = defaultAdditionalInformation;
    this.useSpecificDeliveryAddress = false;
    this.deliveryAddress = defaultDeliveryAddress;
  };

  @action
  initWithExistant = (estimate, configOverride = {}) => {
    const informations = JSON.parse(estimate.information);
    this.financingType = informations.financingForm.financingType || defaultFinancingType;
    this.currency = estimate.currency || "EUR";
    this.paymentCondition = estimate.paymentCondition;
    this.paymentMode = estimate.paymentMode;
    this.financingForm = informations.financingForm;
    this.cashForm = informations.cashForm;
    this.cashFormDeposits = estimate.cashFormDeposits || [];
    this.id = estimate.id;
    this.number = estimate.number;
    this.nature = estimate.nature;
    this.details = informations.details.map(detail => ({
      ...detail,
      discountInputValue: detail.discountInputValue || detail.discount || 0,
      discountType: detail.discountType || "HT",
      discountIsPercentage: detail.discountIsPercentage || false,
      tva: new Decimal(detail.tva).toNumber().toFixed(2)
    }));
    this.discount = estimate.discount;
    this.discountMethod = estimate.discountMethod;
    this.limitDate =
      estimate.limitDate ||
      (configOverride && configOverride.limitDate ? configOverride.limitDate : moment().add(LIMIT_DATE_DAYS, "days"));
    if (estimate.contact) {
      this.client = new Client(estimate.contact);
    } else {
      this.client = {
        type: estimate.clientType,
        businessName: estimate.clientBusinessName || "",
        lastname: estimate.clientLastname || "",
        firstname: estimate.clientFirstname || "",
        phone: estimate.clientPhone || "",
        email: estimate.clientEmail || "",
        address: estimate.clientAddress || "",
        zipCode: estimate.clientZipCode || "",
        city: estimate.clientCity || ""
      };
    }
    this.comments = estimate.comments || "";
    this.optimus = informations.optimus || [];
    this.includePaymentBlock =
      estimate.includePaymentBlock !== undefined
        ? estimate.includePaymentBlock
        : estimate.paymentCondition === "FINANCING";
    this.additionalInformation = {
      defined: estimate.additionalInformation || defaultAdditionalInformation.defined,
      extra: estimate.extraAdditionalInformation || defaultAdditionalInformation.extra
    };
    this.useSpecificDeliveryAddress = Boolean(estimate.deliveryAddress);
    this.deliveryAddress = estimate.deliveryAddress || defaultDeliveryAddress;
    this.updateDetailsDiscountAmounts();
  };

  @action
  toggleIncludePaymentBlock = (value = undefined) => {
    if (value === true || value === false) {
      this.includePaymentBlock = value;
    } else {
      this.includePaymentBlock = !this.includePaymentBlock;
    }
    this.financingForm = defaultFinancingForm;
    this.financingType = defaultFinancingType;
  };

  @action
  updateOptimus = _optimus => {
    this.optimus = [..._optimus];
  };

  @action
  handleChange = event => {
    this[event.target.name] = event.target.value;
  };

  @action
  handleChangeLimitDate = date => {
    this.limitDate = date;
  };

  @action
  handleChangeCurrency = currency => {
    this.currency = currency;
  };

  @action
  handleChangeDiscountMethod = () => {
    if (this.discountMethod === FINANCIAL_METHOD.PERCENTAGE) {
      this.discountMethod = FINANCIAL_METHOD.HARD_VALUE;
    } else {
      this.discountMethod = FINANCIAL_METHOD.PERCENTAGE;
    }
  };

  @action
  handleChangeDepositMethod = () => {
    if (this.cashForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE) {
      this.cashForm.depositMethod = FINANCIAL_METHOD.HARD_VALUE;
    } else {
      this.cashForm.depositMethod = FINANCIAL_METHOD.PERCENTAGE;
    }
  };

  @action
  handleSwapDetails = ({ index, newIndex }) => {
    this.details = Array.from(this.details, (item, aIndex) => {
      if (index === aIndex) {
        return this.details[newIndex];
      }
      if (newIndex === aIndex) {
        return this.details[index];
      }
      return item;
    });
  };

  handleChangeFinancingFormDepositMethod = () => {
    if (this.financingForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE) {
      this.financingForm.depositMethod = FINANCIAL_METHOD.HARD_VALUE;
    } else {
      this.financingForm.depositMethod = FINANCIAL_METHOD.PERCENTAGE;
    }
  };

  @action
  handleChangeClient = event => {
    this.client[event.target.name] = event.target.value;
  };

  @action
  setClient = client => {
    this.client = client;
  };

  @action
  saveDrawing = ({ index, drawing }) => {
    this.details = this.details.map((detail, indexIn) =>
      indexIn === index ? { ...detail, drawing: drawing ? drawing.imgWGrid : null } : detail
    );
  };

  @action
  handleChangeFinancingForm = event => {
    this.financingForm[event.target.name] = event.target.value;
  };

  @action
  handleChangeCashForm = event => {
    this.cashForm[event.target.name] = event.target.value;
  };

  @action
  handleAddDetail = () => {
    this.details = this.details.concat([{ ...new Product(), discountInputValue: 0 }]);
  };

  @action
  handleChangeDetails = index => event => {
    const details = { ...this.details[index] };
    const { name, value } = event.target;
    details[name] = value;
    this.details[index] = details;
    this.updateCashFormDeposits();
    this.updateDetailDiscountAmount(index);
  };

  @action
  handleChangeDetailDiscount = (index, discount) => {
    const details = { ...this.details[index] };
    details.discount = discount;
    this.details[index] = details;
    this.updateCashFormDeposits();
  };

  @action
  handleCustomFieldChange = ({ index, customFieldIndex }) => event => {
    const detail = this.details[index];
    const { customFields } = detail;
    customFields[customFieldIndex].value = event.target.value;
    this.details[index] = { ...detail, customFields };
  };

  @action
  handleChangeDiscountType = index => (e, discountTypeIsTTC) => {
    this.details[index] = { ...this.details[index], discountType: discountTypeIsTTC === true ? "TTC" : "HT" };
    this.updateCashFormDeposits();
  };

  @action
  getMaxDiscountForItem = index => {
    const item = this.details[index];
    if (item.discountType === "TTC") {
      return new Decimal(Number(item.price))
        .mul(Number(item.quantity))
        .mul(100 + Number(item.tva))
        .div(100)
        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
        .toNumber();
    }
    return new Decimal(Number(item.price))
      .mul(Number(item.quantity))
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  };

  @action
  handleChangeAutocomplete = (index, autocomplete) => {
    const { id, title, description, price, customFields } = autocomplete;
    const tva = TVAS_DROPDOWN.map(option => option.value).includes(autocomplete.tva) ? autocomplete.tva : "20.00";
    this.details[index] = { ...this.details[index], id, description, title, price, tva, customFields };
  };

  @action
  handleRemoveDetail = index => () => {
    if (this.details.length !== 1) {
      this.details = this.details.filter((i, ind) => index !== ind);
    } else {
      this.details = [{ ...new Product(), discountInputValue: 0 }];
    }
    this.updateCashFormDeposits();
  };

  @action
  resetNewEstimate = () => {
    this.nature = "";
    this.details = [{ ...new Product(), discountInputValue: 0 }];
    this.discount = "";
    this.discountMethod = FINANCIAL_METHOD.PERCENTAGE;
    this.limitDate = moment().add(LIMIT_DATE_DAYS, "days");
    this.client = new Client();
    this.paymentCondition = PAYMENT_CONDITIONS[0].value;
    this.paymentMode = PAYMENT_MODES[0].value;
    this.financingForm = {
      depositMethod: FINANCIAL_METHOD.PERCENTAGE,
      deposit: "0",
      financingInstitution: "",
      instalmentNumber: "",
      instalmentAmount: "",
      monthlyCostInsurance: "",
      endWorkPayment: "",
      taeg: "",
      overdrawnRate: "",
      totalAmount: ""
    };
    this.cashForm = {
      depositMethod: FINANCIAL_METHOD.HARD_VALUE,
      deposit: "0"
    };
    this.cashFormDeposits = [];
    this.additionalInformation = defaultAdditionalInformation;
    this.financingType = defaultFinancingType;
  };

  @action
  addCashFormDeposit = () => {
    this.cashFormDeposits = [...this.cashFormDeposits, { label: "", inputValue: 0, isPercentage: false, amount: 0 }];
  };

  @action
  removeCashFormDeposit = index => {
    this.cashFormDeposits = this.cashFormDeposits.filter((_, i) => i !== index);
  };

  @action
  updateCashFormDeposit = index => event => {
    const { name, value, checked } = event.target;
    const deposit = { ...this.cashFormDeposits[index] };
    deposit[name] = name === "isPercentage" ? checked : value;
    this.cashFormDeposits[index] = deposit;
    this.updateCashFormDeposits();
  };

  @action
  updateCashFormDeposits = () => {
    this.cashFormDeposits = this.cashFormDeposits.map(deposit => ({
      ...deposit,
      amount: deposit.isPercentage
        ? new Decimal(deposit.inputValue || 0)
            .mul(this.amount)
            .div(100)
            .toNumber()
        : deposit.inputValue || 0
    }));
  };

  @action
  updateDetailDiscount = (index, { discountInputValue, discountIsPercentage, discountType }) => {
    const detail = { ...this.details[index] };
    detail.discountIsPercentage = discountIsPercentage;
    detail.discountInputValue = discountInputValue;
    detail.discountType = discountType;
    this.details[index] = detail;
    this.updateDetailDiscountAmount(index);
  };

  @action
  updateDetailsDiscountAmounts = () => {
    this.details.forEach((detail, index) => {
      this.updateDetailDiscountAmount(index);
    });
  };

  @action
  updateDetailDiscountAmount = index => {
    const detail = { ...this.details[index] };
    if (detail.discountType === "HT") {
      const detailTotal = new Decimal(Number(detail.price))
        .mul(Number(detail.quantity))
        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(2);
      if (detail.discountIsPercentage) {
        const _discountAmount = new Decimal(detail.discountInputValue || 0)
          .mul(detailTotal)
          .div(100)
          .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
          .toNumber();
        detail.discount = _discountAmount;
      } else {
        detail.discount = detail.discountInputValue;
      }
    } else {
      const detailTotalWithTVA = new Decimal(Number(detail.price))
        .mul(Number(detail.quantity))
        .mul((100 + Number(detail.tva)) / 100)
        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
        .toNumber();
      if (detail.discountIsPercentage) {
        const _discountAmount = new Decimal(detail.discountInputValue || 0)
          .mul(detailTotalWithTVA)
          .div(100)
          .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
          .toNumber();
        detail.discount = _discountAmount;
      } else {
        detail.discount = new Decimal(detail.discountInputValue || 0)
          .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
          .toNumber();
      }
    }
    this.details[index] = detail;
    this.updateCashFormDeposits();
  };

  @computed
  get computedCashFormDeposits() {
    return this.cashFormDeposits.reduce((acc, deposit) => acc.add(deposit.amount), new Decimal(0)).toNumber();
  }

  @computed
  get isFirstDetailOk() {
    const { title, description, price, tva, quantity, customFields } = this.details[0];
    let customsOK = true;
    if (customFields && customFields.length > 0) {
      customFields.forEach(field => {
        if (field.required && field.value === "") {
          customsOK = false;
        }
      });
    }

    return title !== "" && description !== "" && price !== "" && tva !== "" && quantity !== "" && customsOK;
  }

  @computed
  get isNewEstimateActionOK() {
    return this.details.every((value, index) => {
      const { title, discount, description, price, tva, quantity, customFields } = value;
      let customsOK = true;
      if (customFields && customFields.length > 0) {
        customFields.forEach(field => {
          if (field.required && field.value === "") {
            customsOK = false;
          }
        });
      }

      return (
        title !== "" &&
        description !== "" &&
        price !== "" &&
        tva !== "" &&
        quantity !== "" &&
        this.nature !== "" &&
        customsOK &&
        ((discount >= 0 && discount <= this.getMaxDiscountForItem(index)) || discount === 0) &&
        this.amount >= 0 &&
        this.amount - this.optimus.reduce((a, b) => a + parseFloat(b.amount), 0) >= 0
      );
    });
  }

  @computed
  get isNewEstimateClientOK() {
    const { lastname, firstname, email, address, zipCode, city, type, phone } = this.client;

    return (
      lastname !== "" &&
      firstname !== "" &&
      email !== "" &&
      ValidatorService.validateEmail(email) &&
      address !== "" &&
      zipCode !== "" &&
      city !== "" &&
      type !== "" &&
      (phone ? ValidatorService.validatePhone(phone) : true)
    );
  }

  @computed
  get isNewEstimateDeliveryAddressOK() {
    if (this.useSpecificDeliveryAddress) {
      const { lastname, firstname, email, address, zipCode, city, phone } = this.deliveryAddress;
      return (
        lastname !== "" &&
        firstname !== "" &&
        email !== "" &&
        ValidatorService.validateEmail(email) &&
        address !== "" &&
        zipCode !== "" &&
        city !== "" &&
        (phone ? ValidatorService.validatePhone(phone) : true)
      );
    }
    return this.isNewEstimateClientOK;
  }

  @computed
  get isNewEstimateComplementaryOK() {
    if (!this.includePaymentBlock) {
      return true;
    }
    if (this.paymentCondition === PAYMENT_CONDITIONS_VALUES.FINANCING) {
      const {
        deposit,
        financingInstitution,
        instalmentNumber,
        instalmentAmount,
        instalmentAmountWithInsurance,
        monthlyCostInsurance,
        endWorkPayment,
        taeg,
        overdrawnRate,
        totalAmount
      } = this.financingForm;

      if (this.financingType === "COFIDIS_PROJEXIO") {
        return instalmentAmountWithInsurance !== "" && instalmentNumber !== "";
      }

      return (
        deposit !== "" &&
        financingInstitution !== "" &&
        instalmentNumber !== "" &&
        instalmentAmount !== "" &&
        monthlyCostInsurance !== "" &&
        endWorkPayment !== "" &&
        taeg !== "" &&
        overdrawnRate !== "" &&
        totalAmount !== ""
      );
    }

    if (this.paymentCondition === PAYMENT_CONDITIONS_VALUES.CASH) {
      return this.computedCashFormBalance >= 0;
    }

    return true;
  }

  @computed
  get numberDetails() {
    return this.details.length;
  }

  /**
   * Get the total price of the current estimate including TVA (Taxe Valeur Ajoutée)
   */
  @computed
  get totalPriceWithTVA() {
    const pricesWithTVA = this.details.map(detail => this.priceWithTVA(detail));

    return pricesWithTVA.reduce((accumulator, price) => Number(accumulator) + Number(price));
  }

  /**
   * Get the total price of the current estimate without TVA (Taxe Valeur Ajoutée)
   */
  @computed
  get totalPriceWithoutTVA() {
    const pricesWithoutTVA = this.details.map(detail => this.priceWithoutTVA(detail));

    return pricesWithoutTVA.reduce((accumulator, price) => Number(accumulator) + Number(price));
  }

  @computed
  get amount() {
    return new Decimal(Number(this.totalPriceWithTVA))
      .sub(Number(this.computedDiscount))
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2);
  }

  /**
   * Compute the discount based on discount method used.
   *
   * * If the discount method is PERCENTAGE, we need to compute the discount by
   * * multiplying the total price including taxes and the discount, then divide
   * * it by 100.
   *
   * * Else if the discount method is HARD_VALUE, we just need to return the
   * * value
   */
  @computed
  get computedDiscount() {
    if (this.discountMethod === FINANCIAL_METHOD.PERCENTAGE) {
      return new Decimal(Number(this.totalPriceWithTVA))
        .mul(Number(this.discount))
        .div(100)
        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(2);
    }

    return Number(this.discount).toFixed(2);
  }

  @computed
  get computedDeposit() {
    if (this.cashForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE) {
      return new Decimal(Number(this.amount))
        .mul(Number(this.cashForm.deposit))
        .div(100)
        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(2);
    }

    return Number(this.cashForm.deposit).toFixed(2);
  }

  @computed
  get computedCashFormBalance() {
    return Number(this.amount - this.computedCashFormDeposits).toFixed(2);
  }

  @computed
  get computedFinancingFormDeposit() {
    if (this.financingForm.depositMethod === FINANCIAL_METHOD.PERCENTAGE) {
      return new Decimal(Number(this.amount))
        .mul(Number(this.financingForm.deposit))
        .div(100)
        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(2);
    }

    return Number(this.financingForm.deposit).toFixed(2);
  }

  @computed
  get computedFinancingFormBalance() {
    return Number(this.amount - this.computedFinancingFormDeposit).toFixed(2);
  }

  /**
   * The price without taxes is computed by multiplying price and quantity.
   */
  priceWithoutTVA = detail => {
    const { discount, discountType, price, quantity, tva } = detail;
    if (discountType === "HT" || discount === 0 || discount === "") {
      return new Decimal(Number(price))
        .mul(Number(quantity))
        .minus(Number(discount || 0))
        .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
        .toNumber()
        .toFixed(2);
    }
    // We need to get the w/ vates amount before all
    return new Decimal(Number(price))
      .mul(Number(quantity))
      .mul((100 + Number(tva)) / 100)
      .minus(Number(discount || 0))
      .div((100 + Number(tva)) / 100)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2);
  };

  priceWithTVA = detail => {
    const { tva } = detail;

    return new Decimal(this.priceWithoutTVA(detail))
      .mul(Number(tva))
      .div(100)
      .add(this.priceWithoutTVA(detail))
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2);
  };

  @computed
  get selectedPaymentMethod() {
    return PAYMENT_CONDITIONS.find(condition => condition.value === this.paymentCondition).component;
  }

  @action
  handleChangePaymentCondition = type => {
    this.paymentCondition = type;
  };

  @computed
  get loanAmount() {
    return new Decimal(this.totalPriceWithTVA || 0)
      .minus(this.computedFinancingFormDeposit || 0)
      .minus(this.financingForm.endWorkPayment || 0)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber();
  }

  @action
  handleChangeFinancingType = newType => {
    this.financingType = newType;
    if (newType === "COFIDIS_PROJEXIO") {
      this.financingForm.financingInstitution = "PROJEXIO";
    }
  };

  @action
  resetFinancingForm = () => {
    this.financingForm = {
      ...this.financingForm,
      ...defaultFinancingValues
    };
  };

  @action
  patchFinancingForm = form => {
    this.financingForm = { ...this.financingForm, ...form };
  };
}

export default new NewEstimateStore();
